import { QHeader, QTab, QTitle, QBodyLayout } from '@qualio/ui-components';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RenderAdminContent } from '../components';
import { UnauthorizedView } from '../containers/unauthorized';
import '../styles/AdminSettings.css';
import { NavList } from '../utils/umfe.enums';
import { Groups, Security, Users } from './admin';
import { HeaderText } from './__displayStrings__/AdminSettings';
import { useFlagProvider } from '../utils/flagProvider';
import { Permissions } from './admin/Permissions/Permissions';
import { BetaFeatures } from './admin/BetaFeatures/BetaFeatures';
import { DevApi } from './admin/DevApi/DevApi';

const getComponentByHref = (href: string) => {
  switch (href) {
    case NavList.USERS.href:
      return <Users />;
    case NavList.GROUPS.href:
      return <Groups />;
    case NavList.PERMISSIONS.href:
      return <Permissions />;
    case NavList.BETAS.href:
      return <BetaFeatures />;
    case NavList.SECURITY.href:
      return <Security />;
    case NavList.DEV_API.href:
      return <DevApi />;
    default:
      return <UnauthorizedView />;
  }
};

export const getAdminSubPath = (pathname: string): string | undefined => {
  const pathParts = pathname.split('/');
  for (let i = 0; i < pathParts.length - 1; i += 1) {
    if (pathParts[i] === 'admin') {
      return pathParts[i + 1];
    }
  }
};

export const AdminSettings: React.FC = () => {
  const navigate = useNavigate();
  const { isFlagEnabled } = useFlagProvider();
  const navItems = useMemo(
    () => Object.values(NavList).filter((item) => !item.featureFlag || isFlagEnabled(item.featureFlag)),
    [isFlagEnabled],
  );
  const defaultNavItem = navItems[0];
  const { pathname } = useLocation();
  const currentPath: string | undefined = getAdminSubPath(pathname);

  const currentIndex = useMemo(() => navItems.findIndex((item) => item.href === currentPath), [currentPath]);

  useEffect(() => {
    if (!currentPath && defaultNavItem) {
      navigate(defaultNavItem.href, { replace: true });
    }
  }, [currentPath]);

  const content = useMemo(
    () =>
      navItems.map(({ href, title }) => (
        <RenderAdminContent key={href} title={title} component={getComponentByHref(href)} href={href} />
      )),
    [navItems],
  );

  const tabs = useMemo(
    () =>
      navItems.map((navItem) => (
        <QTab data-cy={`${navItem.id}-tab-button`} key={navItem.href}>
          {navItem.title}
        </QTab>
      )),
    [navItems],
  );

  // If no nav items are enabled, then we show nothing!
  if (!defaultNavItem) {
    return <UnauthorizedView />;
  }

  return (
    <QBodyLayout.SplitPanelLeftWithTabs
      onChange={(index) => navigate(`/admin/${navItems[index].href}`)}
      index={currentIndex >= 0 ? currentIndex : 0}
      isLazy
    >
      <QHeader>
        <QTitle>{HeaderText}</QTitle>
      </QHeader>
      {tabs}

      {currentIndex >= 0 ? content : <UnauthorizedView />}
    </QBodyLayout.SplitPanelLeftWithTabs>
  );
};
