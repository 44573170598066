import React from 'react';
import { DataProvider, QDataTable, TableMenuItem, createQColumnHelper } from '@qualio/ui-components';
import { ApiKey } from '../../../types';

export type ApiTokensProps = {
  tokens: ApiKey[];
  onDelete: (item: ApiKey) => void;
  onRotate: (item: ApiKey) => void;
};

const columnHelper = createQColumnHelper<ApiKey>();

export const ApiTokensList: React.FC<ApiTokensProps> = ({ tokens, onDelete, onRotate }) => {
  return (
    <DataProvider.Fixed data={tokens}>
      <QDataTable
        columns={[
          {
            header: 'Name',
            accessorKey: 'name',
          },
          columnHelper.date((token) => new Date(token.created_at * 1000), {
            id: 'created_at',
            header: 'Created',
            enableSorting: true,
          }),
          columnHelper.menu({
            items: (
              <>
                <TableMenuItem data-cy="rotate-key" onClick={(item: ApiKey) => onRotate(item)}>
                  Rotate
                </TableMenuItem>
                <TableMenuItem data-cy="delete-key" isDestructive onClick={(item: ApiKey) => onDelete(item)}>
                  Delete
                </TableMenuItem>
              </>
            ),
          }),
        ]}
      />
    </DataProvider.Fixed>
  );
};
