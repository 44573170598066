import {
  QBox,
  QButton,
  QCheckbox,
  QCloseButton,
  QDivider,
  QFormControl,
  QIcon,
  QInput,
  QLink,
  QModal,
  QModalActions,
  QModalBody,
  QModalHeader,
  QSelect,
  QSelectItem,
  QText,
  useToastProvider,
} from '@qualio/ui-components';
import React, { useEffect, useRef, useState } from 'react';
import { useUmAnalytics } from '../../analytics';
import { UserRoleInfoLink } from '../../constants';
import { InviteUserRequest, ModalComponentProps } from '../../types';
import { createErrorToast, createSuccessToast } from '../../utils/toast.utils';
import { UsergroupsList } from '../../utils/umfe.enums';
import { EMAIL_MAX_LENGTH, validateEmail } from '../../utils/validation/email';
import {
  AdminAccessLabel,
  BillingAccessLabel,
  EmailAddressLabel,
  UserRoleInfoLinkText,
  UserRoleLabel,
} from './__displayStrings__/common';
import { HelperTextBasic } from '../../__displayStrings__/helperText';
import {
  CancelBtnLabel,
  EmailAddressHelperText,
  FormerUserMessage,
  InvitationSentFailedText,
  InvitationSentSuccessText,
  InviteUser,
  ResendInvitationLabel,
  SendInvitationLabel,
} from './__displayStrings__/InviteUserFormModal';
import { isMedtechRole } from '../../types/medtechRole.types';
import { ErrorCodes, getErrorMessageOrDefault } from '../../errors/um-errors';
import {
  ActiveUsersLimitReachedErrorMessage,
  AlreadyActiveUserErrorMessage,
  InvalidEmailErrorMessage,
  TotalUsersLimitReachedErrorMessage,
} from '../../errors/__displayStrings__';
import { isAxiosError } from '../../utils/validation/axios';
import { inviteUserInMedtech, isFormerUserCompany } from '../../api/medtech-api';

type InviteUserFormModalProps = ModalComponentProps & {
  companyId: number;
  getUsers: () => Promise<void>;
};

export const InviteUserFormModal = ({ closeModal, companyId, getUsers }: InviteUserFormModalProps) => {
  const analytics = useUmAnalytics();
  const isInit = useRef(true);

  const usergroups = Object.values(UsergroupsList);

  // InviteUserForm fields
  const [email, setEmail] = useState('');
  const [role, setRole] = useState(UsergroupsList.BASIC.value); // Default to Basic user role
  const [isAdmin, setAdminAccess] = useState(false);
  const [isBilling, setBillingAccess] = useState(false);

  const [showFormerUserContent, setShowFormerUserContent] = useState(false);
  const [inviteButtonLabel, setInviteButtonLabel] = useState(SendInvitationLabel);

  // Track Validation
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isFormValid, setFormValid] = useState(false);

  // Track API
  const [isLoading, setLoading] = useState(false);

  const validateEmailValue = () => {
    const isValidEmailBool = validateEmail(email);
    setIsValidEmail(isValidEmailBool);
    setFormValid(isValidEmailBool);
  };

  const { showToast } = useToastProvider();

  // Validate form when email change
  useEffect(() => {
    if (isInit.current) {
      isInit.current = false;
      return;
    }
    validateEmailValue();
  }, [email]);

  useEffect(() => {
    const buttonLabel = showFormerUserContent ? ResendInvitationLabel : SendInvitationLabel;
    setInviteButtonLabel(buttonLabel);
  }, [showFormerUserContent]);

  const handleSubmit = async () => {
    const isFormer = await isFormerUserCompany(companyId, email);

    // If user was part of company and currently not showing the content for former users
    if (isFormer && !showFormerUserContent) {
      setShowFormerUserContent(true);
      return;
    }

    setLoading(true);

    const payload: InviteUserRequest = {
      email,
      role,
      isAdmin,
      isBilling,
    };

    try {
      await inviteUserInMedtech(companyId, payload);

      await getUsers();
      showToast(createSuccessToast(InvitationSentSuccessText));
      analytics.inviteUserSuccess(isAdmin, { role });
      setLoading(false);
      closeModal();
    } catch (error: unknown) {
      setLoading(false);

      const errorMessageMap = {
        [ErrorCodes.ALREADY_ACTIVE_USER]: AlreadyActiveUserErrorMessage(email),
        [ErrorCodes.ACTIVE_USERS_LIMIT_REACHED]: ActiveUsersLimitReachedErrorMessage,
        [ErrorCodes.TOTAL_USERS_LIMIT_REACHED]: TotalUsersLimitReachedErrorMessage,
        [ErrorCodes.INVALID_EMAIL]: InvalidEmailErrorMessage,
      };

      const errorMessage = getErrorMessageOrDefault(error, errorMessageMap, InvitationSentFailedText);

      showToast(createErrorToast(errorMessage));

      const status = isAxiosError(error) ? error?.response?.status : undefined;
      analytics.inviteUserFailed(isAdmin, { role, status });
    }
  };

  const setAdminAccessValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdminAccess(event.target.checked);
  };

  const setBillingAccessValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBillingAccess(e.target.checked);
  };

  const setRoleValue = (e: QSelectItem) => {
    const { value } = e;
    if (isMedtechRole(value)) {
      setRole(value);
    }
  };

  const setEmailValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);

    if (showFormerUserContent) {
      setShowFormerUserContent(false);
    }
  };

  return (
    <QModal onClose={closeModal} size="2xl" isOpen trapFocus={false}>
      <QModalHeader>
        <QText data-cy="heading">{InviteUser}</QText>
        <QCloseButton onClick={closeModal} />
      </QModalHeader>
      <QModalBody>
        <form id="inviteUserForm" aria-label="inviteUserForm" onSubmit={handleSubmit}>
          <QBox pb="2">
            <QFormControl helper={EmailAddressHelperText} label={EmailAddressLabel} size="sm">
              <QInput
                type="email"
                data-cy="email-input"
                data-intercom-target="email-input"
                placeholder={EmailAddressLabel}
                aria-label="email"
                maxLength={EMAIL_MAX_LENGTH}
                value={email}
                onChange={setEmailValue}
                isInvalid={!isValidEmail}
              />
            </QFormControl>
          </QBox>
          <QBox pb="2">
            <QFormControl data-testid="roleSelectFormControl" label={UserRoleLabel} size="sm">
              <QSelect
                aria-label="role"
                data-cy="user-role-select"
                data-intercom-target="user-role-select"
                options={usergroups}
                value={role}
                onChange={(item) => item && setRoleValue(item)}
              />
              <QLink data-cy="learn-more-link" isExternal href={UserRoleInfoLink}>
                {UserRoleInfoLinkText}
              </QLink>
            </QFormControl>
          </QBox>
          <QBox pb="2">
            <QFormControl size="sm" helper={HelperTextBasic}>
              <QCheckbox
                data-cy="admin-access"
                data-intercom-target="admin-access-checkbox-"
                aria-label="admin access"
                onChange={setAdminAccessValue}
              >
                {AdminAccessLabel}
              </QCheckbox>
            </QFormControl>
          </QBox>
          <QBox pb="2">
            <QFormControl size="sm">
              <QCheckbox
                data-cy="billing-access"
                data-intercom-target="billing-access-checkbox"
                aria-label="billing access"
                onChange={setBillingAccessValue}
              >
                {BillingAccessLabel}
              </QCheckbox>
            </QFormControl>
          </QBox>
          <QDivider orientation="horizontal" />
        </form>
        {showFormerUserContent && (
          <QBox p="2" color="InactiveCaptionText">
            <QText>
              <QIcon iconName="AlertTriangle" /> {FormerUserMessage(email)}
            </QText>
          </QBox>
        )}
      </QModalBody>
      <QModalActions>
        <QButton data-cy="cancel-button" variant="outline" onClick={closeModal}>
          {CancelBtnLabel}
        </QButton>
        <QButton
          data-cy="send-invitation-button"
          data-intercom-target="send-invitation-button-"
          type="submit"
          isLoading={isLoading}
          onClick={handleSubmit}
          isDisabled={!isFormValid}
        >
          {inviteButtonLabel}
        </QButton>
      </QModalActions>
    </QModal>
  );
};
