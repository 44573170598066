import { QStack, QButton, QCard, QText, QTitle } from '@qualio/ui-components';
import React from 'react';

export type NoApiTokensProps = {
  handleDevPortalClick: () => void;
  goToCreate: () => void;
};

export const NoApiTokens: React.FC<NoApiTokensProps> = ({ handleDevPortalClick, goToCreate }) => {
  return (
    <QCard>
      <QStack align="center" spacing={4} padding="40px">
        <QTitle>No API tokens created</QTitle>
        <QText>
          Need an API token for scripts or testing? Generate an API token for quick access to the Qualio API.
        </QText>
        <QStack direction={['column', 'row']} padding="20px">
          <QButton data-cy="dev-portal-link" variant="outline" onClick={handleDevPortalClick}>
            View Developer Portal
          </QButton>
          <QButton data-cy="create-token-button" onClick={goToCreate} leftIcon="Plus">
            Create token
          </QButton>
        </QStack>
      </QStack>
    </QCard>
  );
};
