import React from 'react';
import { QStack } from '@qualio/ui-components';
import { Header } from '../containers/header';
import { AdminPageContentProps } from '../types';

export const RenderAdminContent: React.FC<AdminPageContentProps> = ({ title, component, href }) => {
  return (
    <QStack spacing="4">
      {href !== 'dev-api' && <Header content={title} />}
      <div className="um-colored-box-outer">
        <div>{component}</div>
      </div>
    </QStack>
  );
};
