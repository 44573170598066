import {
  QStack,
  useCurrentUser,
  QFormControl,
  QInput,
  QButton,
  useToastProvider,
  QBackLink,
} from '@qualio/ui-components';
import React, { useState } from 'react';

import { useQueryClient } from 'react-query';
import { createErrorToast } from '../../../utils/toast.utils';
import { createDevApiToken } from '../../../api/medtech-api';
import { Header } from '../../../containers/header';
import { NavList } from '../../../utils/umfe.enums';
import { CacheKeys } from '../../../types';
import { NewTokenDisplay } from './NewTokenDisplay';

export const CreateDevApiToken: React.FC = () => {
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();
  const [tokenValue, setTokenValue] = useState<string>();
  const [error, setError] = useState('Invalid token name');
  const [isLoading, setIsLoading] = useState(false);

  const createToken = (name: string) => {
    setIsLoading(true);
    createDevApiToken(currentUser.companyId, name)
      .then((returnPayload) => {
        setTokenValue(returnPayload.token);
        queryClient.invalidateQueries({ queryKey: [CacheKeys.API_KEYS, currentUser.companyId] });
        setIsLoading(false);
      })
      .catch(() => {
        showToast(createErrorToast('Could not create the requested API KEY due to a server error'));
        setIsLoading(false);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements as typeof event.currentTarget.elements & {
      tokenName: HTMLInputElement;
    };
    createToken(formElements.tokenName.value);
  };

  const handleTokenNameChange = (event: { target: { value: any } }) => {
    if (!event.target.value) {
      setError('Invalid token name');
    } else {
      setError('');
    }
  };

  return (
    <div>
      <QBackLink href={NavList.DEV_API.href} data-cy="back-api">
        Back to API tokens
      </QBackLink>
      <Header content="Create Token" />
      <form id="devApiForm" onSubmit={handleSubmit}>
        {tokenValue && <NewTokenDisplay tokenValue={tokenValue} />}
        <QStack spacing={4}>
          <QFormControl label="Name" helper="What's this token for?" data-cy="mfa-enable">
            <QInput
              id="tokenName"
              data-cy="token-name"
              data-testid="token-name"
              width="500px"
              maxLength={50}
              isDisabled={!!tokenValue}
              onChange={handleTokenNameChange}
            />
          </QFormControl>

          <QButton data-cy="create-now-button" type="submit" isLoading={isLoading} isDisabled={!!tokenValue || !!error}>
            Create token
          </QButton>
        </QStack>
      </form>
    </div>
  );
};
