import { QBox, QButton, QSpinner, QStack, QText, useCurrentUser, useToastProvider } from '@qualio/ui-components';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import { environment } from '../../../environments/environment';
import { CreateDevApiToken } from './CreateDevApiToken';
import { NavList } from '../../../utils/umfe.enums';
import { Header } from '../../../containers/header';
import { NoApiTokens } from './NoApiTokens';

import { deleteDevApiToken, listDevApiToken, rotateDevApiToken } from '../../../api/medtech-api';
import { ApiKey, CacheKeys } from '../../../types';
import { ApiTokensList } from './ApiTokensList';
import { createErrorToast } from '../../../utils/toast.utils';
import { NewTokenDisplay } from './NewTokenDisplay';

const createTokenPath = '/admin/dev-api/token';

export const DevApi: React.FC = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const { showToast } = useToastProvider();
  const queryClient = useQueryClient();
  const [tokenValue, setTokenValue] = useState<string>();

  const { isLoading, data } = useQuery([CacheKeys.API_KEYS, currentUser.companyId], () =>
    listDevApiToken(currentUser.companyId),
  );

  const onDelete = (item: ApiKey) => {
    deleteDevApiToken(currentUser.companyId, item.id)
      .then(() => {
        showToast({
          id: 'delete-token',
          replace: true,
          title: 'Delete Developer API token',
          description: 'Developer API token successfully deleted',
          status: 'success',
        });
        queryClient.invalidateQueries({ queryKey: [CacheKeys.API_KEYS, currentUser.companyId] });
      })
      .catch(() => {
        showToast(createErrorToast('Could not delete the requested API KEY due to a server error'));
      });
  };

  const onRotate = (item: ApiKey) => {
    rotateDevApiToken(currentUser.companyId, item.id)
      .then((apiKey) => {
        setTokenValue(apiKey.token);
      })
      .catch(() => {
        showToast(createErrorToast('Could not delete the requested API KEY due to a server error'));
      });
  };

  const goToCreate = async () => {
    navigate(createTokenPath);
  };

  const handleDevPortalClick = async () => {
    const newWindow = window.open(environment.devPortal, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  if (isLoading || data === undefined) {
    return <QSpinner size="sm" />;
  }

  if (pathname.includes(createTokenPath)) {
    return <CreateDevApiToken />;
  }

  return (
    <QStack spacing={10}>
      <QStack direction="row" spacing={12}>
        <div>
          <Header content={NavList.DEV_API.title} />
          <QText>
            API tokens are a secure, unique keys that allows authenticated access to the Qualio API. They enable
            seamless integration with external systems to automate workflows, sync data, and enhance compliance
            tracking.{' '}
            <QButton variant="link" onClick={handleDevPortalClick}>
              Learn more
            </QButton>
          </QText>
        </div>
        {!!data.length && (
          <QBox w="120px">
            <QButton data-cy="create-token-button" onClick={goToCreate}>
              Create token
            </QButton>
          </QBox>
        )}
      </QStack>
      {tokenValue && <NewTokenDisplay tokenValue={tokenValue} />}
      {!!data.length && <ApiTokensList tokens={data} onDelete={onDelete} onRotate={onRotate} />}
      {!data.length && <NoApiTokens handleDevPortalClick={handleDevPortalClick} goToCreate={goToCreate} />}
    </QStack>
  );
};
