import { QualioQFrontEndProvider, CurrentUser as QCurrentUser } from '@qualio/ui-components';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import { environment } from './environments/environment';
import { EnvironmentEnum } from './types';
import { FlagProviderFactory, initFlagProvider } from './utils/flagProvider';
import ErrorBoundary from './views/ErrorBoundary';
import { mockCurrentUser, mockedAnalytics } from './__mockData__';

const environmentName = environment.name || EnvironmentEnum.LOCAL;

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

(window as any).renderUserManagement = async (
  containerId: string,
  maybeCurrentUser: QCurrentUser | undefined,
  segmentAnalytics?: SegmentAnalytics.AnalyticsJS,
) => {
  const isLocal = environmentName === EnvironmentEnum.LOCAL;

  // If local development and no current user, then set to mock user
  const currentUser: QCurrentUser | undefined = !maybeCurrentUser && isLocal ? mockCurrentUser : maybeCurrentUser;

  // Set analytics to mock analytics if local development
  const analytics = isLocal ? mockedAnalytics : segmentAnalytics;

  // If not local and no user, then redirect user to login page
  if (!currentUser) {
    // eslint-disable-next-line no-console
    console.warn('You must be logged in to view this page.');
    window.location.replace(environment.loginUrl);
    return;
  }

  // Initialization of LD prodiver
  initFlagProvider(currentUser);
  const FlagProvider = await FlagProviderFactory();

  ReactDOM.render(
    <ErrorBoundary>
      <FlagProvider>
        <QueryClientProvider client={queryClient}>
          <QualioQFrontEndProvider user={currentUser} analytics={analytics}>
            <App />
          </QualioQFrontEndProvider>
        </QueryClientProvider>
      </FlagProvider>
    </ErrorBoundary>,
    document.getElementById(containerId),
  );
};

(window as any).unmountUserManagement = (containerId: string) => {
  try {
    if (document.getElementById(containerId) !== null) {
      ReactDOM.unmountComponentAtNode(document.getElementById(containerId)!);
    }
  } catch (e) {
    // even with the if statements its possible we have a race condition that is the if states the element exists BUT
    // then before we remove it the Application container has removed it
  }
};
