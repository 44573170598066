import { QAlert } from '@qualio/ui-components';
import React from 'react';

export type NewTokenProps = {
  tokenValue: string;
};

export const NewTokenDisplay: React.FC<NewTokenProps> = ({ tokenValue }) => {
  return (
    <QAlert
      description={`This is your API key, get it now, as you won't be able to retrieve it later! : ${tokenValue}`}
      status="success"
      title="Success"
    />
  );
};
